<template>
  <div
    :class="[
      $style.select__wrapper,
      {
        [$style.select__wrapper_disabled]: disabled
      }
    ]"
    :style="{ fontSize: fontSize }"
  >
    <div :class="[$style.select__select, {[$style.select__select__new]: newTable}]" id="main-content" @click="show = true">
      <span v-if="selectedValue">
        {{ selectedValue.value || selectedValue.name }}
      </span>
      <span v-if="!selectedValue && placeholder">
        {{ placeholder }}
      </span>
      <img
        v-if="selectedValue"
        src="../assets/icons/close-black.svg"
        alt=""
        @click="setValue(null)"
      />
      <img v-if="!selectedValue" src="../assets/icons/select-arrow.svg" alt="" />
    </div>
    <transition name="select">
      <div v-if="show">
        <div :class="$style.select__close" @click="show = false" />
        <div :class="$style.select__dropdown" id="dropdown">
          <div
            v-for="item in items"
            :key="item.id"
            :class="[
              $style.select__option,
              {
                [$style.select__option_disabled]: item.disabled,
                [$style.select__option_selected]: selectedValue
                  ? selectedValue.id === item.id
                  : false
              }
            ]"
            @click="setValue(item)"
          >
            {{ item.value || item.name }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DefaultSelect',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '1rem'
    },
    height: {
      type: String,
      default: '1.5rem'
    },
    newTable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false,
      selectedValue: null
    }
  },
  watch: {
    value() {
      if (this.items) {
        this.selectedValue = this.items.find((e) => e.id === this.value) || null
      }
    },
    items() {
      if (this.value) {
        this.selectedValue = this.items.find((e) => e.id === this.value) || null
      }
    }
  },
  mounted() {
    if (this.value !== null && this.items.length) {
      this.selectedValue = this.items.find((e) => e.id === this.value) || null
    }
  },
  methods: {
    setValue(item) {
      console.log(item)

      if (item && !item.disabled) {
        this.show = false
        this.selectedValue = item
        this.$emit('input', item)
      } else {
        this.show = false
        this.selectedValue = item
        this.$emit('input', item)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.select {
  &__wrapper {
    position: relative;
    border: 1px solid $input-border;
    color: $gray;
    cursor: pointer;
    &_disabled {
      pointer-events: none;
      background-color: lighten($gray, 30%);
    }
  }

  &__select {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    &__new {
      justify-content: space-between;
      width: 100%;
    }
    span {
      flex: 1;
    }
    img {
      width: 1rem;
      margin-left: 5px;
    }
  }

  &__close {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    cursor: default;
  }

  &__dropdown {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 2.7rem;
    color: $gray;
    background: $white;
    border: 1px solid $input-border;
    max-height: 500px;
    overflow-y: auto;
  }

  &__option {
    padding: 0.5rem;
    border-bottom: 1px solid $input-border;
    transition: 0.3s all;

    &:hover {
      background-color: lighten($light-gray, 25%);
      color: white;
      transition: 0.3s all;
    }
    &_disabled {
      background-color: $medium-gray;
      &:hover{
        background-color: $medium-gray!important;
        color: $gray;
        cursor: not-allowed;
      }
    }
    &:last-child {
      border-bottom: none;
    }

    &_selected {
      color: $gray;
    }
  }
}
</style>
