var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.$style.select__wrapper,
    {
      [_vm.$style.select__wrapper_disabled]: _vm.disabled
    }
  ],style:({ fontSize: _vm.fontSize })},[_c('div',{class:[_vm.$style.select__select, {[_vm.$style.select__select__new]: _vm.newTable}],attrs:{"id":"main-content"},on:{"click":function($event){_vm.show = true}}},[(_vm.selectedValue)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedValue.value || _vm.selectedValue.name)+" ")]):_vm._e(),(!_vm.selectedValue && _vm.placeholder)?_c('span',[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.selectedValue)?_c('img',{attrs:{"src":require("../assets/icons/close-black.svg"),"alt":""},on:{"click":function($event){return _vm.setValue(null)}}}):_vm._e(),(!_vm.selectedValue)?_c('img',{attrs:{"src":require("../assets/icons/select-arrow.svg"),"alt":""}}):_vm._e()]),_c('transition',{attrs:{"name":"select"}},[(_vm.show)?_c('div',[_c('div',{class:_vm.$style.select__close,on:{"click":function($event){_vm.show = false}}}),_c('div',{class:_vm.$style.select__dropdown,attrs:{"id":"dropdown"}},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,class:[
            _vm.$style.select__option,
            {
              [_vm.$style.select__option_disabled]: item.disabled,
              [_vm.$style.select__option_selected]: _vm.selectedValue
                ? _vm.selectedValue.id === item.id
                : false
            }
          ],on:{"click":function($event){return _vm.setValue(item)}}},[_vm._v(" "+_vm._s(item.value || item.name)+" ")])}),0)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }